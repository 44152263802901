import * as React from "react"
import Layout from "../components/layout"
import "../styles/blog.css"


const tabs = ["New Posts", "Popular Posts", "All Blog Series"];

const BlogPage = () => {

    const [tab, setTab] = React.useState("New Posts")
    const text = `

    Nobis corporis quis sunt quisquam rerum eligendi. Quasi molestiae accusantium temporibus sunt natus. Deserunt amet recusandae est. Illum amet nam odit ducimus sunt ipsam qui. Mollitia quia dolor repudiandae perferendis nam ea sint magni. Ex ea non minus et similique non.
    
    Quam hic earum dolor. Vero et animi quidem vero non voluptas dicta recusandae. Consequatur nobis occaecati alias aliquid ut. Quia praesentium ratione sequi aliquid. Esse quas sed ut voluptatem distinctio. Sunt at voluptatem nulla ea.
    
    Est voluptates aspernatur et. Exercitationem voluptas suscipit animi ut maxime. Magni amet placeat iure ut doloribus provident. Saepe recusandae inventore consequatur soluta non voluptate aut voluptatem. Labore aliquam a maxime rerum excepturi. Ab tenetur accusantium debitis ratione deleniti aliquid eos.
    
    Et sequi sint autem. Non fugit cupiditate ipsa labore eos ratione. Itaque nobis sunt saepe nihil dolor fugiat minima. Labore et facilis aut optio.
    
    Quis veniam molestias et voluptatem. Alias deleniti qui dolorem soluta et est saepe harum. Repellendus dolor culpa molestiae aliquam nihil at dolorem quidem. Debitis explicabo cupiditate sit alias facere sit in sed.
    Nobis corporis quis sunt quisquam rerum eligendi. Quasi molestiae accusantium temporibus sunt natus. Deserunt amet recusandae est. Illum amet nam odit ducimus sunt ipsam qui. Mollitia quia dolor repudiandae perferendis nam ea sint magni. Ex ea non minus et similique non.
    
    Quam hic earum dolor. Vero et animi quidem vero non voluptas dicta recusandae. Consequatur nobis occaecati alias aliquid ut. Quia praesentium ratione sequi aliquid. Esse quas sed ut voluptatem distinctio. Sunt at voluptatem nulla ea.
    
    Est voluptates aspernatur et. Exercitationem voluptas suscipit animi ut maxime. Magni amet placeat iure ut doloribus provident. Saepe recusandae inventore consequatur soluta non voluptate aut voluptatem. Labore aliquam a maxime rerum excepturi. Ab tenetur accusantium debitis ratione deleniti aliquid eos.
    
    Et sequi sint autem. Non fugit cupiditate ipsa labore eos ratione. Itaque nobis sunt saepe nihil dolor fugiat minima. Labore et facilis aut optio.
    
    Quis veniam molestias et voluptatem. Alias deleniti qui dolorem soluta et est saepe harum. Repellendus dolor culpa molestiae aliquam nihil at dolorem quidem. Debitis explicabo cupiditate sit alias facere sit in sed.
    Nobis corporis quis sunt quisquam rerum eligendi. Quasi molestiae accusantium temporibus sunt natus. Deserunt amet recusandae est. Illum amet nam odit ducimus sunt ipsam qui. Mollitia quia dolor repudiandae perferendis nam ea sint magni. Ex ea non minus et similique non.
    
    Quam hic earum dolor. Vero et animi quidem vero non voluptas dicta recusandae. Consequatur nobis occaecati alias aliquid ut. Quia praesentium ratione sequi aliquid. Esse quas sed ut voluptatem distinctio. Sunt at voluptatem nulla ea.
    
    Est voluptates aspernatur et. Exercitationem voluptas suscipit animi ut maxime. Magni amet placeat iure ut doloribus provident. Saepe recusandae inventore consequatur soluta non voluptate aut voluptatem. Labore aliquam a maxime rerum excepturi. Ab tenetur accusantium debitis ratione deleniti aliquid eos.
    
    Et sequi sint autem. Non fugit cupiditate ipsa labore eos ratione. Itaque nobis sunt saepe nihil dolor fugiat minima. Labore et facilis aut optio.
    
    Quis veniam molestias et voluptatem. Alias deleniti qui dolorem soluta et est saepe harum. Repellendus dolor culpa molestiae aliquam nihil at dolorem quidem. Debitis explicabo cupiditate sit alias facere sit in sed.
    
    `
    return (
        <Layout pageTitle="Peter Mason | Blog" opacity="low">
            <div class="no-scroll">
                <h1>Blog</h1>
            </div>
            <div class="post-table">
                <div class="tabs">
                    {tabs.map(f => (
                        <div class="tab">
                            <input
                                type="radio"
                                name="css-tabs"
                                class="tab-switch"
                                id={f}
                                value={f}
                                checked={tab === f}
                                onChange={e => setTab(e.currentTarget.value)}
                            />
                            <label for={f} class="tab-label">{f}</label>
                        </div>
                    ))}
                </div>
                <div class="tab-content">{text}</div>
            </div>
        </Layout>
    );
}

export default BlogPage